import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const CREDIT_NOTES_URI = new InjectionToken<string>('Credit Notes BFF URI', {
  factory() {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/credit-notes`;
  },
});
