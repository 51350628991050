import { EntitySequence, IdType } from '@supy/common';

import {
  CreateOrderRequest,
  OrderInvoice,
  OrderProduct,
  ReturnOrderItemsRequest,
  UpdateOrderRequest,
} from '../../core';
import { OrdersFilters, OrdersRequestMetadata } from '../state';

export class OrdersGet {
  static readonly type = '[Orders] Get';
  constructor(readonly payload?: { id: string; fromCache?: boolean }) {}
}

export class OrdersGetDetailed {
  static readonly type = '[Orders] GetDetailed';
  constructor(readonly payload: { id: string; fromCache?: boolean }) {}
}

export class OrdersResetDetailed {
  static readonly type = '[Orders] ResetDetailed';
}

export class OrdersReset {
  static readonly type = '[Orders] Reset';
}

export class OrdersGetMany {
  static readonly type = '[Orders] GetMany';
  constructor(readonly excludeProducts?: boolean) {}
}

export class OrdersGetAggregated {
  static readonly type = '[Orders] GetAggregated';

  constructor(readonly payload: { orders: IdType[] }) {}
}

export class OrdersResetAggregated {
  static readonly type = '[Orders] ResetAggregated';
}

export class OrdersInitFilters {
  static readonly type = '[Orders] InitFilters';
}

export class OrdersSetFilter {
  static readonly type = '[Orders] SetFilter';
  constructor(readonly payload?: OrdersFilters) {}
}

export class OrdersPatchFilter {
  static readonly type = '[Orders] PatchFilter';
  constructor(readonly payload?: Partial<OrdersFilters>) {}
}

export class OrdersResetFilter {
  static readonly type = '[Orders] ResetFilter';
}

export class OrdersPatchRequestMeta {
  static readonly type = '[Orders] PatchRequestMeta';
  constructor(readonly payload: Partial<OrdersRequestMetadata>) {}
}

export class OrdersCreate {
  static readonly type = '[Orders] Create';
  constructor(readonly payload: CreateOrderRequest) {}
}

export class OrdersUpdate {
  static readonly type = '[Orders] Update';
  constructor(readonly payload: { id: string; body: UpdateOrderRequest }) {}
}

export class OrdersUpdateInvoice {
  static readonly type = '[Orders] UpdateInvoice';
  constructor(readonly payload: { id: string; body: OrderInvoice }) {}
}

export class OrdersUploadInvoiceImage {
  static readonly type = '[Orders] OrdersUploadInvoiceImage';
  constructor(readonly payload: { id: string; formData: FormData }) {}
}

export class OrdersClose {
  static readonly type = '[Orders] Close';
  constructor(readonly payload: { id: string }) {}
}

export class OrdersReopen {
  static readonly type = '[Orders] Reopen';
  constructor(readonly payload: { id: string }) {}
}

export class OrdersCloseMany {
  static readonly type = '[Orders] Close Many';
  constructor(readonly payload: { orders: IdType[] }) {}
}

export class ReturnOrderItems {
  static readonly type = '[Orders] ReturnOrderItems';
  constructor(readonly payload: { id: string; body: ReturnOrderItemsRequest }) {}
}

export class AddOrderItems {
  static readonly type = '[Orders] AddOrderItems';
  constructor(readonly items: OrderProduct[]) {}
}

export class OrdersSetSequence {
  static readonly type = '[Orders] SetSequence';
  constructor(readonly payload: Partial<EntitySequence>) {}
}

export class OrdersGetProducts {
  static readonly type = '[Orders] GetProducts';
  constructor(readonly payload?: { id: string }) {}
}

export class OrdersDownloadDeliveryNote {
  static readonly type = '[Orders] DownloadDeliveryNote';
  constructor(readonly orderId: string) {}
}

export class OrdersDownloadDeliveryNoteMany {
  static readonly type = '[Orders] DownloadDeliveryNoteMany';
  constructor(readonly orders: IdType[]) {}
}

export class OrdersDownloadLPO {
  static readonly type = '[Orders] DownloadLPO';
  constructor(readonly orderId: string) {}
}

export class OrdersGetUnlinked {
  static readonly type = '[Orders] GetUnlinked';
  constructor(readonly payload?: { supplierId: string; locationId: string }) {}
}

export class OrdersResetUnlinked {
  static readonly type = '[Orders] ResetUnlinked';
}
