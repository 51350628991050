import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SupyCommonModule } from '@supy/common';
import {
  AvatarModule,
  ButtonModule,
  CheckboxModule,
  CounterInputModule,
  DateInputModule,
  DialogModule,
  DropdownModule,
  FiltersModule,
  GridModule,
  IconModule,
  InputModule,
  LoadingOverlayModule,
  PaginationModule,
  StatusBadgeModule,
  TabViewSelectModule,
} from '@supy/components';

import { RetailerChannelItemsDrawerComponent } from './retailer-channel-items-drawer';
import { RetailerLinkedGrnsComponent } from './retailer-linked-grns';
import { RetailerOrderDetailsCombinedWidgetComponent } from './retailer-order-details-combined-widget';
import { RetailerOrderDetailsDrawerHeaderComponent } from './retailer-order-details-drawer-header';
import { RetailerOrderDetailsHeaderComponent } from './retailer-order-details-header';
import { RetailerOrderDetailsWidgetComponent } from './retailer-order-details-widget';
import { RetailerOrderStatusBadgeComponent } from './retailer-order-status-badge';
import { RetailerOrdersGridComponent } from './retailer-orders-grid';
import { RetailerOrdersGridFiltersComponent } from './retailer-orders-grid-filters';

const COMPONENTS = [
  RetailerOrdersGridComponent,
  RetailerOrdersGridFiltersComponent,
  RetailerOrderStatusBadgeComponent,
  RetailerOrderDetailsWidgetComponent,
  RetailerOrderDetailsCombinedWidgetComponent,
  RetailerOrderDetailsHeaderComponent,
  RetailerOrderDetailsDrawerHeaderComponent,
  RetailerChannelItemsDrawerComponent,
  RetailerLinkedGrnsComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    GridModule,
    IconModule,
    TabViewSelectModule,
    FiltersModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    StatusBadgeModule,
    DialogModule,
    ButtonModule,
    AvatarModule,
    InputModule,
    CheckboxModule,
    SupyCommonModule,
    PaginationModule,
    RouterModule,
    DropdownModule,
    CounterInputModule,
    DateInputModule,
  ],
  exports: [...COMPONENTS],
})
export class RetailerOrdersModule {}
