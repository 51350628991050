import { Channel } from '@supy/channels';
import {
  Branch,
  IdType,
  LocalizedData,
  PreferredType,
  Retailer,
  SkeletonObjectType,
  Supplier,
  Uom,
  User,
} from '@supy/common';

import { CalculatedField, OrderStatus, ReceiveOrderType } from './common-order.entity';
import { Order } from './order.entity';
import { OrderItem } from './order-item.entity';
import { OrderProduct } from './order-product.entity';
import { OrderUpdateStatus } from './order-update.entity';

export enum CreationSourceEnum {
  Location = 'location',
  CentralKitchen = 'central-kitchen',
}

export enum DiscountType {
  Value = 'value',
  Percentage = 'percentage',
}

export interface InvoiceNumberResponse {
  readonly prefixId: string;
  readonly value: string;
}

export interface DiscountResponse {
  readonly value: number;
  readonly type: DiscountType;
}

export interface GetOrderByEmailIdResponse {
  readonly order: Order;
  readonly products: OrderItem;
  readonly user: User;
  readonly retailer: Branch;
  readonly supplier: Supplier;
  readonly channel: Channel;
}

export interface DownloadOrderPdfResponse {
  signedUrl: string;
}

export interface DownloadDeliveryNoteResponse {
  signedUrl: string;
}

export interface ModifyOrderRequest {
  readonly products: ModifiedOrderItem[];
}

export interface RejectOrderRequest {
  readonly message: string;
}

export interface ReturnOrderItemsRequest {
  readonly referenceId?: string;
  readonly products: ModifiedOrderItem[];
}

export interface OrderModificationHistory {
  readonly name: string;
  readonly productId: string;
  readonly oldValue: number;
  readonly newValue: number;
  readonly unit: string;
}

export interface ModifiedOrderItem {
  readonly productId: string;
  readonly quantity: number;
}

export interface UpdateOrderRequest {
  readonly comment?: string;
  readonly deliveryDate?: Date;
  readonly invoice?: Invoice;
  readonly modified?: UpdateOrderItemRequest[];
  readonly ordered?: UpdateOrderItemRequest[];
  readonly received?: UpdateOrderItemRequest[];
  readonly retailerId?: string;
  readonly supplierId?: string;
  readonly type: OrderUpdateStatus;
  readonly updateStock?: boolean;
  readonly shipDate?: Date;
}

export interface Invoice {
  readonly numbers: string[];
  readonly images: string[];
}

export interface UpdateOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface CreateOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface CreateOrderRequest {
  readonly supplierId: string;
  readonly retailerId: string;
  readonly channelId: string;
  readonly deliveryDate: Date;
  readonly comment?: string;
  readonly status: OrderStatus;
  readonly ordered: CreateOrderItemRequest[];
}

export interface OrderRequestProps {
  readonly 'branch.id': string;
  readonly 'branch.name': string;
  readonly 'channel.preferredType': PreferredType;
  readonly 'country.id': string;
  readonly 'customer.id': string;
  readonly 'products.preferredType': PreferredType;
  readonly 'supplier.id': string;
  readonly 'supplier.metadata.retailerId': string;
  readonly 'total.ordered': string;
  readonly 'total.received': string;
}

export interface DatePeriodResponse {
  readonly startDate: Date;
  readonly endDate: Date;
}

export interface BranchQuantity {
  readonly quantity: number;
  readonly branch: SkeletonObjectType;
}

export interface AggregatedSupplierOrderItemResponse {
  readonly itemCode: string;
  readonly name: LocalizedData;
  readonly unit: Uom;
  readonly totalQuantity: number;
  readonly branchQuantities: BranchQuantity[];
}

export interface AggregatedSupplierOrderResponse extends Order {
  readonly confirmationLink: string;
}

export interface AggregatedSupplierOrdersResponse {
  readonly retailers: Retailer[];
  readonly orderPeriod: DatePeriodResponse;
  readonly deliveryPeriod: DatePeriodResponse;
  readonly branches: Branch[];
  readonly groupedItems: AggregatedSupplierOrderItemResponse[];
  readonly aggregatedOrders: AggregatedSupplierOrderResponse[];
}

export interface OrderReceiveRequest {
  readonly type: ReceiveOrderType;
  readonly comment: string;
  readonly products: OrderProduct[];
  readonly discount?: CalculatedField;
  readonly vat?: CalculatedField;
  readonly fees?: number;
  readonly total?: number;
  readonly updateStock?: boolean;
  readonly closed?: boolean;
}

export interface DownloadOrderPdfResponse {
  signedUrl: string;
}

export interface ShipManyRequest {
  readonly orderIds: IdType[];
  readonly updateStock: boolean;
  readonly shipDate: Date;
}
