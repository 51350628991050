import { Currency } from '@supy.api/dictionaries';

import { IdType, LocalizedSkeletonObjectType, SimpleUser, SkeletonObjectType, SupplierSnapshot } from '@supy/common';

import { SimpleChannel } from '../order';
import { SupplierReturnDocumentRequest, SupplierReturnDocumentResponse } from './supplier-return-document.model';
import { SupplierReturnItemRequest, SupplierReturnItemResponse } from './supplier-return-item.model';

export enum SupplierReturnStatus {
  Saved = 'saved',
  Posted = 'posted',
  New = 'new',
  Pending = 'pending',
  Discarded = 'discarded',
}

export enum SupplierReturnActivityType {
  Saved = 'saved',
  Posted = 'posted',
  Pushed = 'pushed',
  Transferred = 'transferred',
}

export interface SupplierReturnActivity {
  readonly comment?: string;
  readonly type: SupplierReturnActivityType;
  readonly createdAt: Date;
  readonly user: SimpleUser;
}

export interface SupplierReturnMetadata {
  readonly isLocked?: boolean;
  readonly isSynced?: boolean;
}

export interface SupplierReturnTotals {
  readonly amount?: number;
  readonly count?: number;
  readonly grandTotal?: number;
  readonly subTotal?: number;
  readonly taxAmount?: number;
  readonly total?: number;
}

export interface SupplierReturnResponse {
  readonly activities: SupplierReturnActivity[];
  readonly channel: SimpleChannel;
  readonly createdAt: Date;
  readonly createdBy: SimpleUser;
  readonly currency: Currency;
  readonly document: SupplierReturnDocumentResponse;
  readonly id: string;
  readonly items: SupplierReturnItemResponse[];
  readonly location: SkeletonObjectType;
  readonly metadata: SupplierReturnMetadata;
  readonly outlet: LocalizedSkeletonObjectType;
  readonly retailer: SkeletonObjectType;
  readonly status: SupplierReturnStatus;
  readonly supplier: SupplierSnapshot;
  readonly totals: SupplierReturnTotals;
  readonly updatedAt: Date;
  readonly comment?: string;
}

export type SupplierReturnPostManyResponse = {
  readonly synced: string[];
  readonly unsynced: string[];
} | void;

export interface SupplierReturnPostManyRequest {
  readonly supplierReturns: IdType[];
}

export interface SupplierReturnLockManyRequest {
  readonly supplierReturns: IdType[];
}

export interface SupplierReturnBaseRequest {
  readonly comment?: string;
  readonly document: SupplierReturnDocumentRequest;
  readonly items: SupplierReturnItemRequest[];
  readonly notifySupplier?: boolean;
  readonly pushToStock?: boolean;
  readonly updatePackaging?: boolean;
}

export interface SupplierReturnCreateRequest extends SupplierReturnBaseRequest {
  readonly channel: IdType;
  readonly location: IdType;
  readonly retailer: IdType;
  readonly supplier: IdType;
  readonly creationSource: 'location';
}

export type SupplierReturnUpdateRequest = Partial<SupplierReturnBaseRequest>;

export interface SupplierReturnGenerateDocumentNumberResponse {
  readonly number: string;
}

export interface SupplierReturnGenerateDocumentNumberRequest {
  readonly retailer: IdType;
}

export interface SupplierReturnDownloadPdfResponse {
  readonly signedUrl: string;
}

export interface SupplierReturnUploadResponse {
  readonly path: string;
}

export interface SupplierReturnQueryProps {
  readonly id: string;
  readonly status: string;
  readonly 'retailer.id': string;
  readonly 'supplier.id': string;
  readonly 'location.id': string;
  readonly 'document.number': string;
  readonly 'document.documentDate': number;
}
