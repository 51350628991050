<div class="retailer-order-details-combined-widget">
  <div class="retailer-order-details-combined-widget--initial-loading" *ngIf="isInitialLoading"></div>
  <div *ngFor="let widget of widgets; trackBy: trackBy" class="retailer-order-details-combined-widget__unit">
    <div class="retailer-order-details-combined-widget__header">
      <supy-icon *ngIf="widget?.headerIcon" [name]="widget?.headerIcon" size="small"></supy-icon>
      <h5 class="retailer-order-details-combined-widget__header-title">{{ widget.header }}</h5>
    </div>
    @if (widget.isDateInput) {
    <supy-date-input
      [value]="defaultDeliveryDate()"
      [ianaTimeZone]="ianaTimeZone()"
      [minValue]="widget.minValue()"
      [maxValue]="maximumDate()"
      (valueChange)="deliveryDateChanged.emit($event)"
    />
    } @else {
    <h6
      class="retailer-order-details-combined-widget__content"
      [ngClass]="{ 'retailer-order-details-combined-widget__content-with-margin': widget?.headerIcon }"
    >
      {{ widget.content }}
    </h6>
    }
  </div>
</div>
