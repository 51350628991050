import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CategoryOrderActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Process extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.Process,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ProcessDraft extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.ProcessDraft,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Discard extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.Discard,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateSuppliers extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CategoryOrder,
      action: CategoryOrderActionEnum.UpdateSupplier,
      resource,
      principal,
    });
  }
}

export class CategoryOrdersPermissionStrategy {
  static Create = Create;
  static View = View;
  static Process = Process;
  static Discard = Discard;
  static ProcessDraft = ProcessDraft;
  static UpdateSuppliers = UpdateSuppliers;
}
