import { Branch, Retailer, Supplier, User } from '@supy/common';
import { TaxRate } from '@supy/settings';

import { SimpleGrn } from '../grn';
import { SimpleChannel, StatefulOrderEntity } from './common-order.entity';
import { BaseOrder } from './order.entity';
import { OrderProduct } from './order-product.entity';
import { OrderUpdateStatus } from './order-update.entity';

export class DetailedOrder extends BaseOrder {
  readonly branch: Branch;
  readonly channel: SimpleChannel; // FIXME: circular deps issue when import Channel type
  readonly channelOrderIndex?: number;
  readonly discount?: number;
  readonly fees?: number;
  readonly linkedGrns: SimpleGrn[];
  readonly orderedTotal?: number;
  readonly products: OrderProduct[];
  readonly ckItems?: OrderProduct[]; // FIXME: circular deps issue when importing CkOrderItemB2bResponse
  readonly receivedDate?: number;
  readonly retailer: Retailer;
  readonly submittedDate?: number;
  readonly supplier: Supplier;
  readonly total: StatefulOrderEntity;
  readonly updatedAt: number;
  readonly updates: DetailedOrderUpdate[];
  readonly user: User;
  readonly vat?: number = 0;
  readonly appliedTax?: TaxRate;
  readonly metadata: {
    readonly closed?: boolean;
  };

  readonly submittedAt?: Date;

  readonly submittedBy?: User;
  readonly draftedBy?: User;
  readonly discardedBy?: User;

  constructor(data: Partial<DetailedOrder>) {
    super(data);

    Object.assign(this, data);
    this.submittedBy = this.getSubmittedBy();
    this.draftedBy = this.getDraftedBy();
    this.discardedBy = this.getDiscardedBy();
    // TODO: remove after merging ck v2
    this.appliedTax = this.appliedTax?.rate ? this.appliedTax : this.products.at(0)?.appliedTax;
  }

  private getSubmittedBy?(): User {
    return this.updates.find(({ status }) => status == OrderUpdateStatus.submitted)?.user;
  }

  private getDraftedBy?(): User {
    return this.updates.find(({ status }) => status === OrderUpdateStatus.draft)?.user;
  }

  private getDiscardedBy?(): User {
    return this.updates.find(({ status }) => status === OrderUpdateStatus.discarded)?.user;
  }
}

export interface DetailedOrderUpdate {
  readonly status: OrderUpdateStatus;
  readonly comment?: string;
  readonly user: User;
  readonly createdAt: Date;
}
